var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('content-title',{attrs:{"nav":_vm.nav}}),_c('el-form',{attrs:{"inline":true}},[_c('el-form-item',{attrs:{"label":"展示平台"}},[_c('el-select',{attrs:{"placeholder":"选择公告展示平台","clearable":""},model:{value:(_vm.query.platform),callback:function ($$v) {_vm.$set(_vm.query, "platform", $$v)},expression:"query.platform"}},[_c('el-option',{attrs:{"label":"全部","value":"all"}}),_c('el-option',{attrs:{"label":"教练端","value":"coach"}}),_c('el-option',{attrs:{"label":"机构端","value":"mechanism"}})],1)],1),_c('el-form-item',{attrs:{"label":"状态"}},[_c('el-select',{attrs:{"placeholder":"选择公告状态","clearable":""},model:{value:(_vm.query.status),callback:function ($$v) {_vm.$set(_vm.query, "status", $$v)},expression:"query.status"}},[_c('el-option',{attrs:{"label":"上架中","value":1}}),_c('el-option',{attrs:{"label":"已下架","value":2}})],1)],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.getList(1)}}},[_vm._v("搜索")])],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"success"},on:{"click":_vm.add}},[_vm._v("新增")])],1)],1),_c('auto-table',{attrs:{"DataList":_vm.DataList,"option":_vm.Option,"total":_vm.Total},on:{"changePage":_vm.getList},scopedSlots:_vm._u([{key:"platform",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(_vm.typePlatform[row.platform]))])]}},{key:"type",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(_vm.type[row.type]))])]}},{key:"status",fn:function(ref){
var row = ref.row;
return [(row.status === 1)?_c('el-tag',{attrs:{"size":"small","type":"success"}},[_vm._v("上架")]):_vm._e(),(row.status === 2)?_c('el-tag',{attrs:{"size":"small"}},[_vm._v("已下架")]):_vm._e()]}},{key:"topping",fn:function(ref){
var row = ref.row;
return [(row.topping)?_c('el-tag',{attrs:{"size":"small"}},[_vm._v("置顶")]):_vm._e()]}},{key:"handle",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"size":"small","type":"success"},on:{"click":function($event){return _vm.update(row)}}},[_vm._v("编辑")]),(row.status === 1)?_c('el-button',{attrs:{"size":"small","type":"danger"},on:{"click":function($event){return _vm.updateStatus(row, 2)}}},[_vm._v("下架")]):_vm._e(),(row.status === 2)?_c('el-button',{attrs:{"size":"small","type":"danger"},on:{"click":function($event){return _vm.updateStatus(row, 1)}}},[_vm._v("上架")]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }