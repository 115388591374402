<template>
  <div class="container">
    <content-title :nav="nav"></content-title>
    <el-form :inline="true">
      <el-form-item label="展示平台">
        <el-select
          v-model="query.platform"
          placeholder="选择公告展示平台"
          clearable
        >
          <el-option label="全部" value="all"></el-option>
          <el-option label="教练端" value="coach"></el-option>
          <el-option label="机构端" value="mechanism"></el-option>
        </el-select>
      </el-form-item>
        <el-form-item label="状态">
        <el-select
          v-model="query.status"
          placeholder="选择公告状态"
          clearable
        >
          <el-option label="上架中" :value="1"></el-option>
          <el-option label="已下架" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getList(1)">搜索</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="success" @click="add">新增</el-button>
      </el-form-item>
    </el-form>

    <auto-table
      :DataList="DataList"
      :option="Option"
      :total="Total"
      @changePage="getList"
    >
      <template #platform="{ row }">
        <div>{{ typePlatform[row.platform] }}</div>
      </template>
        <template #type="{ row }">
        <div>{{ type[row.type] }}</div>
      </template>
      <template #status="{ row }">
        <el-tag size="small" type="success" v-if="row.status === 1"
          >上架</el-tag
        >
        <el-tag size="small" v-if="row.status === 2">已下架</el-tag>
      </template>
       <template #topping="{ row }">
        <el-tag size="small" v-if="row.topping">置顶</el-tag>
      </template>

      
      <template #handle="{ row }">
        <el-button size="small" type="success" @click="update(row)"
          >编辑</el-button
        >
        <el-button size="small" v-if="row.status === 1" type="danger" @click="updateStatus(row, 2)"
          >下架</el-button
        >
           <el-button size="small" v-if="row.status === 2" type="danger" @click="updateStatus(row, 1)"
          >上架</el-button
        >
      </template>
    </auto-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      nav: { firstNav: "咨询公告管理", secondNav: "公告列表" },
      DataList: [],
      query: {
        platform: "",
        status: "",
      },
      typePlatform: {
        all: "全部平台",
        coach: "教练端",
        mechanism: "机构端",
      },
      type: {
        notice: "资讯",
        publice: "公告",
      },
      Option: [
        { name: "标题", value: "title" },
        { name: "发布人", value: "create_user_name" },
        { name: "展示平台", value: "platform", type: "custom" },
         { name: "类型", value: "type", type: "custom" },
        { name: "置顶", value: "topping",  type: "custom" },
        { name: "创建时间", value: "create_time" },
        { name: "状态", value: "status", type: "custom" },
        { name: "操作", value: "handle", type: "custom", width: "300px" },
      ],
      Total: 0,
    };
  },

  mounted() {
    this.getList(1);
  },

  methods: {
    add() {
      this.$router.push("noticeEdit");
    },
    update(row) {
      this.$router.push({ name: "noticeEdit", query: { id: row.id } });
    },
    userDetail(row) {
      this.$router.push({ name: "userDetails", params: { id: row.user_id } });
    },
    updateStatus(row, status) {
      let data = {
        id: row.id,
        status,
      };
      this.$axios.post("/user/notice/update", data).then(() => {
        this.$message("成功");
        this.getList(1);
      });
    },
    //列表
    getList(v) {
      let page = v || 1;
      this.$axios({
        url: "/user/notice/queryManagerListPage",
        params: {
          currentPage: page,
          pageSize: 10,
          platform: this.query.platform || null,
           status: this.query.status || null,
        },
      }).then((res) => {
        this.DataList = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>